import "../css/styles.scss"
import * as Message from "./message"
import bootstrap from "./bootstrap"
import gallery from "./gallery"
import reduxSetup from "./reduxSetup"
import setupEmailReveal from "./email-reveal"
import Stagecraft from "./stagecraft"

window.store = reduxSetup()

window.gallery = gallery
window.setupEmailReveal = setupEmailReveal
window.showMessageDialog = Message.showDialog
window.messageForm = Message.messageForm

bootstrap(window.store)

document.addEventListener("DOMContentLoaded", function() {
  window.stagecraft = new Stagecraft()
  Message.setupDialog()
});
