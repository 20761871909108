export function gallery(id) {
  document.addEventListener("DOMContentLoaded", function() {
    const container = document.getElementById(id)

    container.addEventListener("click", function(event) {
      event.stopPropagation()
      let entry = event.target
      while (!entry.classList.contains("gallery__entry")) {
        entry = entry.parentElement
      }
      window.stagecraft.toggle(entry)
    })
  });
}

export default gallery
