import { combineReducers } from "redux";
import contact from "./contact"
import name from "./name"
import session from "./session"
import visitor from "./visitor"

const root = combineReducers({
  contact,
  name,
  session,
  visitor,
})

export default root
