export const type = "SetContact"

export function set(value) {
  return {
    type,
    value,
  }
}

export default set
