import Cookies from "js-cookie"
import thunkPromise from "./thunkPromise"

export const CookieName = "v"
export const type = "LoadVisitor"

export async function createVisitorId() {
  const fingerprint = [
    navigator.userAgent,
    new Date().toString(),
    window.outerHeight,
    window.outerWidth,
  ]
  const data = fingerprint.join("")
  if (window.crypto && window.crypto.subtle) {
    const encoder = new TextEncoder()
    const bytes = encoder.encode(data)
    const hash = await crypto.subtle.digest('SHA-256', bytes)
    const visitorId = btoa(String.fromCharCode.apply(null, new Uint8Array(hash)))
    return visitorId
  }
  const visitorId = btoa(data)
  return visitorId
}

export function loadVisitor() {
  const value = Cookies.get(CookieName)
  if (value) {
    return {
      source: "Cookie",
      type,
      value
    }
  }
  const p = createVisitorId().then(
    (visitorId) => {
      return {
        source: "New",
        type,
        value: visitorId
      }
    },
    console.error
  )
  return thunkPromise(p)
}

export default loadVisitor
