import Cookies from 'js-cookie'

import { CookieName as SessionCookie } from "./actions/loadSession"
import { CookieName as VisitorCookie } from "./actions/loadVisitor"

export async function trail(store) {
  switch (navigator.doNotTrack) {
    case "1":
      Cookies.remove(SessionCookie)
      Cookies.remove(VisitorCookie)
      break
    case "0":
    case "unspecified":
    default:
      store.subscribe(() => {
        const state = store.getState()
        if (state.session) {
          Cookies.set(SessionCookie, state.session)
        }
        if (state.visitor) {
          Cookies.set(VisitorCookie, state.visitor, {
            expires: 365 * 5
          })
        }
      })

  }
}

export default trail