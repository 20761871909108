export const type = "LoadContact"
export const Key = "Contact"

export function load() {
  const value = window.localStorage.getItem(Key)
  return {
    type,
    value,
  }
}

export default load
