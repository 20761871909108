import { type as load } from "../actions/loadContact"
import { type as set } from "../actions/setContact"

export function visitor(state, action) {
  switch (action.type) {
    case load:
    case set:
      return action.value
  }
  return state || null
}

export default visitor
