import { type as load } from "../actions/loadSession"

export function session(state, action) {
  switch (action.type) {
    case load:
      return action.value
  }
  return state || null
}

export default session
