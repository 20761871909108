import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import root from "./reducers/root"

export function reduxSetup() {
  const store = createStore(root, composeWithDevTools(
    applyMiddleware(thunk),
  ));

  return store
}

export default reduxSetup
