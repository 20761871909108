import loadContact from "./actions/loadContact"
import loadName from "./actions/loadName"
import loadSession from "./actions/loadSession"
import loadVisitor from "./actions/loadVisitor"
import saveContact from "./components/saveContact"
import saveName from "./components/saveName"
import trail from "./trail"

export function bootstrap(store) {
  store.dispatch(loadVisitor())
  store.dispatch(loadSession())
  trail(store)
  store.dispatch(loadName())
  store.dispatch(loadContact())
  saveContact(store)
  saveName(store)
}

export default bootstrap
