export class Stagecraft {
  constructor() {
    this.close = document.getElementById("stagecraft__close")

    document.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        this.flood()
      }
    })

    this.close.addEventListener("click", (event) => {
      event.stopPropagation()
      this.flood()
    })

    document.addEventListener("click", (event) => {
      const classList = event.target.classList
      if (classList.contains("stagecraft__apron")
        && classList.contains("spotlight")) {
        event.stopPropagation()
        this.flood()
      }
    })
  }

  flood(except = undefined) {
    const aprons = document.getElementsByClassName("stagecraft__apron")
    for (let element of aprons) {
      if (element !== except) {
        element.classList.remove("spotlight")
      }
    }
    if (!except) {
      this.close.classList.remove("visible")
    }
  }

  toggle(apron) {
    if (this.hasSpotlight(apron)) {
      this.flood()
    }
    else {
      this.spotlight(apron)
    }
  }

  spotlight(apron) {
    this.flood(apron)
    if (apron) {
      apron.classList.add("spotlight")
      this.close.classList.add("visible")
    }
  }

  toggleOnClick(id) {
    const apron = document.getElementById(id)
    if (apron) {
      apron.addEventListener("click", (event) => {
        this.toggle(apron)
      })
    }
  }

  hasSpotlight(apron) {
    if (apron) {
      return apron.classList.contains("spotlight")
    }
    return false
  }
}

export default Stagecraft
