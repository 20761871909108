import Cookies from "js-cookie"

export const type = "LoadSession"
export const CookieName = "s"

export function loadSession() {
  let value = Cookies.get(CookieName)
  let source = "Cookie"
  if (!value) {
    value = Date.now().toString()
    source = "New"
  }
  return {
    source,
    type,
    value,
  }
}

export default loadSession
