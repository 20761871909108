export function logError(error) {
  console.log("Error dispatching async action", error)
}

export function thunkPromise(promise, onError = logError) {
  return function(dispatch) {
    promise.then(
      (action) => {
        dispatch(action)
      },
      (error) => {
        onError(error, dispatch)
      }
    )
  }
}

export default thunkPromise
