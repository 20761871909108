import { type as load } from "../actions/loadVisitor"

export function visitor(state, action) {
  switch (action.type) {
    case load:
      return action.value
  }
  return state || null
}

export default visitor
