export function reveal() {
  const element = document.getElementById("email-reveal")
  const a = 'crai' + 'gslin' + 'kedl' + 'ist' + '\u0040'
  +  'kano' + 'ok.n' + 'et'
  const href = 'ma' + 'ilt' + 'o:' + a

  element.innerHTML = `<a class="email_reveal__content" href='${href}'>${a}</span>`
  element.classList.add("active")
}

export function setup() {
  document.addEventListener("DOMContentLoaded", function() {
    const element = document.getElementById("email-reveal")
    if (element) {
      element.addEventListener("click", function(event) {
        event.stopPropagation()
        reveal()
      })
    }
  });
}

export default setup