export const type = "LoadName"
export const Key = "Name"

export function load() {
  const value = window.localStorage.getItem(Key)
  return {
    type,
    value,
  }
}

export default load
