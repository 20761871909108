import { Key } from "../actions/loadContact"

export function save(store) {
  store.subscribe(() => {
    const state = store.getState()
    if (state.contact) {
      window.localStorage.setItem(Key, state.contact)
    }
    else {
      window.localStorage.removeItem(Key)
    }
  })
}

export default save
